import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const FooterContainer = styled.footer``

const Copyright = styled.p`
  text-align: center;
  font-size: 0.5rem;
  font-family: "Roboto";
  margin: 2rem 0;
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `)

  return (
    <FooterContainer>
      <Copyright>
      </Copyright>
    </FooterContainer>
  )
}

export default Footer
